import { LOGIN_CALLBACK_ROUTE } from 'src/app/app-routing.module';

export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyBZLt0CmbxpHCMgRDcj8PX_NkUx-hQWK28',
    authDomain: 'hcp-portal-dev-e6c56.firebaseapp.com',
    projectId: 'hcp-portal-dev',
    storageBucket: 'hcp-portal-dev.appspot.com',
    messagingSenderId: '122386466765',
    appId: '1:122386466765:web:cf62332635a9c0214ca684',
  },
  okta_domain: 'mylivanova.oktapreview.com',
  okta_clientId: '0oazud6ebcZmGrrv40h7',
  redirectUri: `https://dev.mylivanova.com/${LOGIN_CALLBACK_ROUTE}`,
};
