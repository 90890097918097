import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator } from '@angular/forms';
import { CustomValidators } from './custom-validators';

@Directive({
  selector: '[appEmail]',
  providers: [{ provide: NG_VALIDATORS, useExisting: EmailDirective, multi: true }],
})
export class EmailDirective implements Validator {
  /**
   * This `validate` method which confirms to `ValidatorFn` is implemented from
   * the `Validator` interface. Since the `CustomValidators.email` method also conforms
   * to `ValidatorFn` it is assigned to this class's `validate` function.
   *
   * @param control - AbstractControl
   * @returns `EmailValidationErrors` or `null`
   */
  validate = CustomValidators.email;
}
