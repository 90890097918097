import { Component, OnInit } from '@angular/core';
import { trace } from '@angular/fire/performance';
import { Router } from '@angular/router';
import { AuthenticationService } from '../common/services/authentication.service';

@Component({ template: `Logging in...` })
export class OktaCallbackComponent implements OnInit {
  constructor(private authenticationService: AuthenticationService, private router: Router) {}

  async ngOnInit(): Promise<void> {
    /**
     * Subscribing to AuthenticationService.isAuthenticated which will
     * trigger navigation to protected routes upon recieving a true value.
     * This approach is required because race conditions exist between
     * completing login and the propagation of the authState. Immiditate
     * navigation to protected routes will cause the AuthGuard to initiate
     * another Okta login and this will result in a callback-loop.
     */
    this.authenticationService.isAuthenticated
      .pipe(trace('OktaCallbackComponent -> authenticationService.isAuthenticated'))
      .subscribe((isAuthenticated) => {
        if (isAuthenticated) {
          this.router.navigate(['/']);
        }
      });
    await this.authenticationService.handleLoginCallback();
  }
}
