import { AbstractControl, ValidationErrors } from '@angular/forms';
import { RegExpDefinitions } from 'shared';

export class CustomValidators {
  /**
   * Email validator
   *
   * @remarks
   * Implementing function that confirms to type `ValidatorFn` which
   * will allow this Validator to be used in `directives` as well as Forms.
   *
   * @param control - AbstractControl
   * @returns `EmailValidationErrors` or `null`
   */
  static email(control: AbstractControl): EmailValidationErrors | null {
    const input = control.value as string;
    if (!input) {
      return {
        required: true,
        invalid: true,
      } as EmailValidationErrors;
    }
    const email = input.trim();
    if (email.length == 0) {
      return {
        required: true,
        invalid: true,
      } as EmailValidationErrors;
    }

    const test = RegExpDefinitions.email.test(email);
    if (test) {
      return null;
    } else {
      return { invalid: true, required: false } as EmailValidationErrors;
    }
  }

  static password(control: AbstractControl): ValidationErrors | null {
    const input = control.value as string;
    if (!input) {
      return {
        invalid: true,
        required: true,
      } as PasswordValidationErrors;
    }
    const password = input.trim();
    if (password.length == 0) {
      return {
        invalid: true,
        required: true,
      } as PasswordValidationErrors;
    }

    const test = RegExpDefinitions.password.test(password);
    if (test) {
      return null;
    } else {
      const error: PasswordValidationErrors = { invalid: true };
      if (password.length < 6) {
        error.tooShort = true;
      }
      if (password.length > 64) {
        error.tooLong = true;
      }
      return error;
    }
  }
}
/**
 * Defines the map of errors returned from failed email validation checks.
 * Includes explecit optional `boolean` fields `required` and `invalid.`
 */
export type EmailValidationErrors = ValidationErrors & {
  required: boolean;
  invalid: boolean;
};

/**
 * Defines the map of errors returned from failed password validation checks.
 * Includes explicit optional `boolean` fields `invalid`, `tooLong`, `tooShort`, `required`.
 */
export type PasswordValidationErrors = ValidationErrors & {
  invalid?: boolean;
  tooLong?: boolean;
  tooShort?: boolean;
  required?: boolean;
};
