import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator } from '@angular/forms';
import { CustomValidators } from './custom-validators';

@Directive({
  selector: '[appPassword]',
  providers: [{ provide: NG_VALIDATORS, useExisting: PasswordDirective, multi: true }],
})
export class PasswordDirective implements Validator {
  /**
   * This `validate` method which confirms to `ValidatorFn` is implemented from
   * the `Validator` interface. Since the `CustomValidators.password` method also conforms
   * to `ValidatorFn` it is assigned to this class's `validate` function.
   *
   * @param control - AbstractControl
   * @returns `PasswordValidationErrors` or `null`
   */
  validate = CustomValidators.password;
}
