import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appInputLimiter]',
})
/**
 * @public
 */
export class InputLimiterDirective {
  @Input('maxLength') appInputLimiterMaxLength?: number;
  @Input('canPaste') appInputLimiterCanPaste: boolean = true;
  @Input('pool') appInputLimiterPool?: string;
  nativeEl = this.elementRef.nativeElement;
  constructor(public elementRef: ElementRef) {}

  /**
   * This method is invoked when the `onPaste` event is fired on the attached component.
   * @remanks
   * This method is implemented from the using the `@HostListener` decorator interface.
   * @param event - ClipboardEvent
   */
  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
    event.stopPropagation();
    event.preventDefault();
    if (this.appInputLimiterCanPaste) {
      const pastedText = event.clipboardData?.getData('Text');
      if (pastedText) {
        this.pasteText(pastedText);
      }
    }
  }

  /**
   * This method is invoked when the `onKeydownHandler` event is fired on the attached component.
   *
   * @remanks
   * This method is implemented from the using the `@HostListener` decorator interface.
   *
   * @param event - `KeyboardEvent`
   */
  @HostListener('keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.ctrlKey || event.metaKey) {
      return true;
    }
    switch (event.key) {
      case 'Backspace':
      case 'Enter':
      case 'ArrowLeft':
      case 'ArrowRight':
      case 'ArrowUp':
      case 'ArrowDown':
      case 'Tab':
        return true;
      default: {
        if (this.appInputLimiterPool && !this.appInputLimiterPool.includes(event.key)) {
          return false;
        }
        if (this.nativeEl.selectionStart != this.nativeEl.selectionEnd) {
          return true;
        }
        return !(this.appInputLimiterMaxLength && this.appInputLimiterMaxLength <= this.nativeEl.value.length);
      }
    }
  }

  /**
   * This method strips provided `pastedText` string of invalid characters before pasting.
   *
   * @remanks
   * This method is implemented from the using the `@HostListener` decorator interface.
   *
   * @internal
   * @param pastedText - string.
   */
  private pasteText(pastedText: string) {
    const textToPaste = this.appInputLimiterPool ? this.filterString(pastedText) : pastedText;
    const selectionStart = this.nativeEl.selectionStart;
    const selectionEnd = this.nativeEl.selectionEnd;
    const selectionLength = selectionEnd - selectionStart;
    const existingText = this.nativeEl.value;
    let newText: string;
    if (this.appInputLimiterMaxLength) {
      const remainingLength = this.appInputLimiterMaxLength - this.nativeEl.value.length;
      newText =
        existingText.substr(0, selectionStart) +
        textToPaste.substr(0, remainingLength + selectionLength) +
        existingText.substr(selectionEnd);
    } else {
      newText = existingText.substring(0, selectionStart) + textToPaste + existingText.substring(selectionEnd);
    }
    this.nativeEl.value = newText;
  }

  /**
   * @internal
   * @param text - string
   * @returns a `string` stripped of forbidden characters.
   */
  private filterString(text: string): string {
    if (!this.appInputLimiterPool) {
      return text;
    }
    return [...text]
      .filter((character) => {
        return this.appInputLimiterPool!.includes(character);
      })
      .join('');
  }
}
