import { CdkStepper } from '@angular/cdk/stepper';
import { Component } from '@angular/core';

@Component({
  selector: 'livn-stepper',
  templateUrl: './livn-stepper.component.html',
  styleUrls: ['./livn-stepper.component.scss'],
  providers: [{ provide: CdkStepper, useExisting: LivnStepperComponent }],
})
export class LivnStepperComponent extends CdkStepper {
  /**
   * Sets the steppers selected step to step with value `index`.
   *
   * @param index - The index of the step being selected.
   */
  selectStepByIndex(index: number): void {
    this.selectedIndex = index;
  }
}
